import { data } from 'jquery';
import { UsersService } from 'app/users/users.service';
import { GlobalConstants } from 'app/global-constants';
import { Component, OnInit} from '@angular/core';

declare var $:any;

@Component({
    moduleId: module.id,
    selector: 'fixedplugin-cmp',
    templateUrl: 'fixedplugin.component.html',
    styleUrls: ['./fixedplugin.component.css']
})

export class FixedPluginComponent implements OnInit{

    constructor(private userService:UsersService){}
    hidden:boolean = false;
    ngOnInit(){
        var $sidebar = $('.sidebar');
        var $btn_style = $('.main-content')

        var $off_canvas_sidebar = $('.off-canvas-sidebar');
        var window_width = window.outerWidth;
        var $navbar = $('.navbar')
        if(window_width > 767){
            if($('.fixed-plugin .dropdown').hasClass('show-dropdown')){
                $('.fixed-plugin .dropdown').addClass('open');
            }
        }

        var $theme = localStorage.getItem('theme')
        var $theme_active_color = localStorage.getItem('text_color')
        $off_canvas_sidebar.attr('data-color',$theme);
        $off_canvas_sidebar.attr('data-active-color',$theme_active_color);

        $sidebar.attr('data-color',$theme);
        
        // changer_color_btn($theme)
        
        GlobalConstants.setColor($theme);
        $btn_style.attr('data-btn-style',$theme)

        $('.fixed-plugin a').click(function(event){
          // Alex if we click on switch, stop propagation of the event, so the dropdown will not be hide, otherwise we set the  section active
            if($(this).hasClass('switch-trigger')){
                if(event.stopPropagation){
                    event.stopPropagation();
                }
                else if(window.event){
                   window.event.cancelBubble = true;
                }
            }
        });

        $('.fixed-plugin .background-color span').click(function(){
            $(this).siblings().removeClass('active');
            $(this).addClass('active');

            var new_color = $(this).data('color');
            // this.postTheme({theme:new_color})
            GlobalConstants.setColor(new_color);

            if($sidebar.length != 0){
                $sidebar.attr('data-color',new_color);
                // changer_color_btn(new_color)
            }

            if($off_canvas_sidebar.length != 0){
                $off_canvas_sidebar.attr('data-color',new_color);
                // changer_color_btn(new_color)
            }
            GlobalConstants.themeButton()
            $btn_style.attr('data-btn-style',localStorage.getItem('theme'))
        });

        $('.fixed-plugin .active-color span').click(function(){
            $(this).siblings().removeClass('active');
            $(this).addClass('active');

            var new_color = $(this).data('color');
            // this.postTheme({text_color: new_color})
            
            if($sidebar.length != 0){
                $sidebar.attr('data-active-color',new_color);
            }

            if($off_canvas_sidebar.length != 0){
                $off_canvas_sidebar.attr('data-active-color',new_color);
            }
        });
    }

    postTheme(data){
        this.userService.postThemeTpl(data).subscribe((response)=>{
            let user = JSON.parse(JSON.stringify(response)) 
            localStorage.setItem('theme',user.theme)
            localStorage.setItem('text_color',user.text_color)
        },(error)=>{
            console.log(error)
        })
    }

    handlerTheme($event){
        let color = $event.target.dataset.color
        this.postTheme({theme: color,email: GlobalConstants.current_user().email})
    }
    handlerActionColorTheme($event){
        let color = $event.target.dataset.color
        this.postTheme({text_color: color, email: GlobalConstants.current_user().email})
    }
}


function changer_color_btn(new_color) {
    var $button = $('.btn-icon')
    var $btn = $('.btn')
    $button.removeClass('btn-success')
    $button.removeClass('btn-primary')
    $button.removeClass('btn-secondary')
    $button.removeClass('btn-default')
    
    $btn.removeClass('btn-success')
    $btn.removeClass('btn-primary')
    $btn.removeClass('btn-secondary')
    $btn.removeClass('btn-default')
    $('.main-content').attr('data-btn-style',localStorage.getItem('theme'))

    switch (new_color) {
        case 'teal':
            $button.addClass('btn-success')
            $btn.addClass('btn-success')
        case 'midnight':
            $button.addClass('btn-default')
        case 'indigo':
            $button.addClass('btn-default')
            $btn.addClass('btn-default')
            break;
        case 'brown':
            $button.addClass('btn-secondary')
            $btn.addClass('btn-secondary')
            break;
        case 'white':
            $button.addClass('btn-secondary')
            $btn.addClass('btn-secondary')
            break
        case 'blueberry':
            $button.addClass('btn-secondary')
            $btn.addClass('btn-secondary')
            break 
        case 'periwinkle':
            $button.addClass('btn-secondary')
            $btn.addClass('btn-secondary')
            break
        case 'peacock':
            $button.addClass('btn-secondary')
            $btn.addClass('btn-secondary')
            break
        case 'sky':
            $button.addClass('btn-secondary')
            $btn.addClass('btn-secondary')
            break
        case 'lavender':
            $button.addClass('btn-secondary')
            $btn.addClass('btn-secondary')
            break
        case 'overcast':
            $button.addClass('btn-secondary')
            $btn.addClass('btn-secondary')
            break
        default:
            break;
    }
}