import { GlobalConstants } from 'app/global-constants';
import { LoadingService } from './loading.service';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,HttpResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import {catchError, map} from 'rxjs/operators'
import { Router, RouterStateSnapshot } from '@angular/router';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {

  constructor( private _loading: LoadingService,private router: Router) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this._loading.setLoading(true, request.url);
    this._loading.setErrorLogin(false,request.url);
    return next.handle(request)
      .pipe(catchError((err) => {
        
        this._loading.setLoading(false, request.url);

        if(err.error.non_field_errors){
          this._loading.setErrorLogin(true, request.url)
        }
        // else{
        //   GlobalConstants.errorRequest(err)
        // }
        
        if (err.status === 401){
          if(localStorage.getItem('token')){
            localStorage.clear();
            let url: string = this.router.url;
            this.router.navigate([`pages/login`],{ queryParams: { q: url } });
          }
          // this.router.navigate(['pages/login'],{ queryParams: { url: window.location.href } })
        }
        return err;
      }))
      .pipe(map<HttpEvent<any>, any>((evt: HttpEvent<any>) => {
        if (evt instanceof HttpResponse) {
          this._loading.setLoading(false, request.url);
          this._loading.setErrorLogin(false, request.url);
        }
        return evt;
      }));
  }

}
