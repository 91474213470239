import { Injectable} from '@angular/core';
import { CanActivate, CanActivateChild, Router, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute } from '@angular/router';
import { GlobalConstants } from 'app/global-constants';


@Injectable()
export class AuthGuardService implements CanActivate, CanActivateChild {
  	
  constructor(private router: Router,private route: ActivatedRoute,) {}

  //permissions
  view_dossier = GlobalConstants.has_permission("view_dossiers")
  view_depot = GlobalConstants.has_permission("view_depot") 
  view_users = GlobalConstants.has_permission("view_users")
  view_prealable = GlobalConstants.has_permission("view_prealable")

  view_recepisse = GlobalConstants.has_permission("view_recepisse")
  view_bordereau = GlobalConstants.has_permission("view_bordereau")
  view_fiche_immeuble = GlobalConstants.has_permission("view_fiche_imme")
  // view_avis
  view_titre = GlobalConstants.has_permission("view_titre")
  view_lettre = GlobalConstants.has_permission("view_lettre")
  // view_total_dossier
  // view_B_titre
  // view_journal
  // view_geometre
  // view_document
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    this.isAuthenticated()
    let url: string = state.url;
    if(localStorage.getItem("token") && this.isAuthenticated()) return true; 
    
    this.router.navigate([`pages/login`],{ queryParams: { q: url } });
  return false;
        
  }
  canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    let url: string = state.url;

    if(localStorage.getItem("token") && this.isAuthenticated()) return true; 
      
      this.router.navigate([`pages/login`],{ queryParams: { q: url } });
  return false;
        
  }  

  isAuthenticated() {
    if (this.view_dossier || this.view_depot || this.view_users || this.view_prealable || this.view_recepisse || this.view_bordereau || this.view_fiche_immeuble || this.view_lettre || this.view_titre){
      return true;
    }
    return false;
  }
}


@Injectable()
export class AuthGuardChildService implements CanActivate {

  //permissions
  view_dossier = GlobalConstants.has_permission("view_dossiers")
  view_depot = GlobalConstants.has_permission("view_depot") 
  view_users = GlobalConstants.has_permission("view_users")
  view_prealable = GlobalConstants.has_permission("view_prealable")

  view_recepisse = GlobalConstants.has_permission("view_recepisse")
  view_bordereau = GlobalConstants.has_permission("view_bordereau")
  view_fiche_immeuble = GlobalConstants.has_permission("view_fiche_imme")
  // view_avis
  view_titre = GlobalConstants.has_permission("view_titre")
  view_lettre = GlobalConstants.has_permission("view_lettre")
  view_total_dossier = GlobalConstants.has_permission("view_total_dossier")
  view_B_titre = GlobalConstants.has_permission("view_B_titre")
  view_journal = GlobalConstants.has_permission("view_journal")
  view_document = GlobalConstants.has_permission("view_document")
  
  constructor(private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
      let url: string = state.url;
      if(this.isAuthenticated()) return true; 
      this.router.navigate([`pages/login`],{ queryParams: { q: url } });
    return false;
  }

  isAuthenticated() {
    if (this.view_dossier || this.view_depot || this.view_users || this.view_prealable || this.view_recepisse || this.view_bordereau || this.view_fiche_immeuble || this.view_lettre || this.view_titre || this.view_B_titre || this.view_journal || this.view_document || this.view_total_dossier){
      return true;
    }
    this.router.navigate(['dashboard']);
    return false;
  }
}