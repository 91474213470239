import { GlobalConstants } from 'app/global-constants';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs/Rx';


export interface Message {
	sender: string,
	message: string
}

@Injectable()
export class NotificationService {

	headers: HttpHeaders;
	estActualiser: boolean
	messages: any;
  
	constructor(private http: HttpClient) {
	  this.estActualiser = GlobalConstants.fresh_navigator() 
	}
	getHeader(){
	  this.headers = new HttpHeaders({
		'Content-Type':  'application/json',
		'Authorization': `Token ${localStorage.getItem("token")}`
	  })
	  return this.headers
	}
  

	getNotification(type):Observable<any>{
		return this.http.get(`${GlobalConstants.NOTIFICATION_URL}?type=${type}`,{headers : this.getHeader()});
	}

	updteReadNotification(notif_id):Observable<any>{ 
		return this.http.get(`${GlobalConstants.UPDATENOTIFICATION_URL}${notif_id}/`,{headers:this.getHeader()});
	}
	  
	
}
