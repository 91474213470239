import { Routes } from '@angular/router';

import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';

import { AuthGuardService } from './pages/auth-guard.service';

// StatistiqueModule
export const AppRoutes: Routes = [{
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
    },{
        path: '',
        component: AdminLayoutComponent,
        children: [{
            path: '',
            loadChildren: './dashboard/dashboard.module#DashboardModule',
            canActivate: [AuthGuardService],
            canActivateChild: [AuthGuardService],
        },{
            path: 'users',
            loadChildren: './users/users.module#UsersModule',
            canActivate: [AuthGuardService],
            canActivateChild: [AuthGuardService],
        },
        {
            path: 'tracks',
            canActivate: [AuthGuardService],
            canActivateChild: [AuthGuardService],
            loadChildren: './users/users.module#UsersModule'
        },{
            path: 'user',
            loadChildren: './userpage/user.module#UserModule',
            canActivate: [AuthGuardService],
            canActivateChild: [AuthGuardService],
        },
        {
            path:'configs',
            loadChildren: './configs/configs.module#ConfigsModule',
            canActivate: [AuthGuardService],
            canActivateChild: [AuthGuardService],
        },
        {
            path:'registres',
            loadChildren: './registres/registres.module#RegistresModule',
            canActivate: [AuthGuardService],
            canActivateChild: [AuthGuardService],
        }
        /*
        * Dossiers all module
        */
       ,{
            path:'dossiers',
            loadChildren: './dossiers/dossier/dossier.module#DossierModule',
            canActivate: [AuthGuardService],
            canActivateChild: [AuthGuardService],
        }
        ,{
            path:'dossiers',
            loadChildren: './dossiers/steppers/steppers.module#SteppersModule',
            canActivate: [AuthGuardService],
            canActivateChild: [AuthGuardService],
        },{
            path:'dossiers',
            loadChildren: './dossiers/attachment/attachment.module#AttachmentModule',
            canActivate: [AuthGuardService],
            canActivateChild: [AuthGuardService],
        },
        {
            path:'dossiers',
            loadChildren: './dossiers/recepisse-depot/recepisse-depot.module#RecepisseDepotModule',
            canActivate: [AuthGuardService],
            canActivateChild: [AuthGuardService],
        },
        {
            path:'dossiers',
            loadChildren: './dossiers/avis-information/avis-information.module#AvisInformationModule',
            canActivate: [AuthGuardService],
            canActivateChild: [AuthGuardService],
        },
        {
            path:'dossiers',
            loadChildren: './dossiers/bordereau/bordereau.module#BordereauModule',
            canActivate: [AuthGuardService],
            canActivateChild: [AuthGuardService],
        },
        {
            path:'dossiers',
            loadChildren: './dossiers/certificat-oppositions/certificat-oppositions.module#CertificatOppositionsModule',
            canActivate: [AuthGuardService],
            canActivateChild: [AuthGuardService],
        },
        {
            path:'dossiers',
            loadChildren: './dossiers/fiche-conservateur-adjoint/fiche-conservateur-adjoint.module#FicheConservateurAdjointModule',
            canActivate: [AuthGuardService],
            canActivateChild: [AuthGuardService],
        },
        {
            path:'dossiers',
            loadChildren: './dossiers/immeuble/immeuble.module#ImmeubleModule',
            canActivate: [AuthGuardService],
            canActivateChild: [AuthGuardService],
        },
        {
            path:'dossiers',
            loadChildren: './dossiers/immeuble/immeuble.module#ImmeubleModule',
            canActivate: [AuthGuardService],
            canActivateChild: [AuthGuardService],
        },
        {
            path:'dossiers',
            loadChildren: './dossiers/lettre-information/lettre-information.module#LettreInformationModule',
            canActivate: [AuthGuardService],
            canActivateChild: [AuthGuardService],
        },
        {
            path:'dossiers',
            loadChildren: './dossiers/proprietaire/proprietaire.module#ProprietaireModule',
            canActivate: [AuthGuardService],
            canActivateChild: [AuthGuardService],
        },
        {
            path:'dossiers',
            loadChildren: './dossiers/requisition/requisition.module#RequisitionModule',
            canActivate: [AuthGuardService],
            canActivateChild: [AuthGuardService],
        },
        {
            path:'dossiers',
            loadChildren: './dossiers/assignations/assignations.module#AssignationsModule',
            canActivate: [AuthGuardService],
            canActivateChild: [AuthGuardService],
        },
        {
            path:'dossiers',
            loadChildren: './dossiers/titre-foncier/titre-foncier.module#TitreFoncierModule',
            canActivate: [AuthGuardService],
            canActivateChild: [AuthGuardService],
        },
        {
            path:'dossiers',
            loadChildren: './dossiers/demandes-opposition/demandes-opposition.module#TitreFoncierModule',
            canActivate: [AuthGuardService],
            canActivateChild: [AuthGuardService],
        }
    ]
    },
    {
        path: '',
        component: AuthLayoutComponent,
        children: [{
            path: 'pages',
            loadChildren: './pages/pages.module#PagesModule'
        }]
    },
];
