import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';

import { GlobalConstants } from '../global-constants';
import { Observable, Subject } from 'rxjs';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  headers: HttpHeaders;
  estActualiser: boolean
  messages: any;

  constructor(private http: HttpClient) {
    this.estActualiser = GlobalConstants.fresh_navigator()
    
  }

  getHeader(){
    this.headers = new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': `Token ${localStorage.getItem("token")}`
    })
    return this.headers
  }

  getUsers(pageIndex:number = 0,links = {next:null,previous:null}, pageSize=20, q=""):Observable<any> {
    let loc = window.location
    if(pageIndex === 0){
      if(q){
        return this.http.get(`${GlobalConstants.USERS_URL}?page_size=${pageSize}&q=${q}`,{headers:this.getHeader()})
      }
      return this.http.get(`${GlobalConstants.USERS_URL}?page_size=${pageSize}`,{headers:this.getHeader()})
    }else{
      if(links.next){
        let next_link = links.next
        if(loc.protocol == 'https:' || loc.protocol == 'http:'){
          let _http = loc.protocol == 'https:' ? 'https' : 'http';
          next_link = next_link.split(":",2)[1]
          next_link = `${_http}:${next_link}`
        }
        if(q){
          return this.http.get(`${GlobalConstants.USERS_URL}?page_size=${pageSize}&q=${q}`,{headers:this.getHeader()})
        }
        if(environment.production){
          return this.http.get(`${next_link}&page_size=${pageSize}`,{headers:this.getHeader()})
        }else{
          return this.http.get(`${links.next}&page_size=${pageSize}`,{headers:this.getHeader()})
        }
      }
      
      if(links.previous){
        let previous_link = links.previous
        if(loc.protocol == 'https:' || loc.protocol == 'http:'){
          let _http = loc.protocol == 'https:' ? 'https' : 'http';
          previous_link = previous_link.split(":",2)[1]
          previous_link = `${_http}:${previous_link}`
        }
        if(q){
          return this.http.get(`${GlobalConstants.USERS_URL}?page_size=${pageSize}&q=${q}`,{headers:this.getHeader()})
        }
        if(environment.production){
          return this.http.get(`${previous_link}&page_size=${pageSize}`,{headers:this.getHeader()})
        }else{
          return this.http.get(`${links.previous}&page_size=${pageSize}`,{headers:this.getHeader()})
        }
      }

    }
  }

  getSingleUser(action_user):Observable<any>{
    let httpOptions = {
      headers: this. getHeader()
    };
    return this.http.get(`${GlobalConstants.USER_URL}?action_user=${action_user}`, httpOptions);
  }
  
  // recuperer les actions
  getAction(list_action):Observable<any>{
    return this.http.get(`${GlobalConstants.TRACK_ACTION_ADD_URL}?estActualiser=${this.estActualiser}&list_action=${list_action}`,{headers : this.getHeader()});
  }
  postAction(data){
    let httpOptions = {
      headers: this.getHeader()
    };
    return this.http.post(GlobalConstants.TRACK_ACTION_ADD_URL, data,httpOptions)
  }
  

  getUserById(data):Observable<any>{
    let httpOptions = {
      headers: this.getHeader()
    };
    return this.http.post(GlobalConstants.USER_DETAIL_URL, data,httpOptions)
  }

  getSingleTypeUser(data):Observable<any>{
    let httpOptions = {
      headers: this.getHeader()
    };
    return this.http.post(GlobalConstants.SINGLE_TYPE_USER, data,httpOptions)
  }

  updateTypeUser(data){
    let httpOptions = {
      headers: this.getHeader()
    };
    return this.http.post(GlobalConstants.TYPE_USER_UPDATE, data,httpOptions)
  }

  addUser(data){
    let httpOptions = {
      headers: this.getHeader()
    };
    return this.http.post(GlobalConstants.REGISTER_URL, data,httpOptions)
  }

  addTypeUser(data){
    let httpOptions = {
      headers: this.getHeader()
    };
    return this.http.post(GlobalConstants.TYPE_USER_ADD_URL, data,httpOptions)
  }

  getTypeUsers(action_user):Observable<any>{
    let httpOptions = {
      headers: this.getHeader()
    };
    return this.http.get(GlobalConstants.TYPE_USER_LIST_URL+`?action_user=${action_user}`,httpOptions);
  }

  changePassword(data){
    let httpOptions = {
      headers: this. getHeader()
    };
    return this.http.put(GlobalConstants.CHANGE_PASSWORD_URL, data,httpOptions)
  }

  updateUser(data,profile){  
    let httpOptions = {
      headers: this.getHeader()
    };
    return this.http.post(`${GlobalConstants.UPDATE_USER_URL}?profile=${profile}`, data,httpOptions)
  }

  sendReqResetPass(data){  
    let httpOptions = {
      headers: this.getHeader()
    };
    return this.http.post(GlobalConstants.REQUEST_RESET_USER_PASSWORD_URL, data,httpOptions)
  }

  logOut(){
    let httpOptions = {
      headers: this.getHeader()
    };
    return this.http.get(GlobalConstants.REQUEST_LOGOUT_USER,httpOptions)
  }

  getLogOut(){  
    let httpOptions = {
      headers: this.getHeader()
    };
    return this.http.post(GlobalConstants.USER_LOGOUT_URL,{},httpOptions)
  }
  
  postThemeTpl(data){  
    let httpOptions = {
      headers: this.getHeader()
    };
    return this.http.post(`${GlobalConstants.THEME_TPL}${this.getUser().id}`,data,httpOptions)
  }

  getToken(){
     return localStorage.getItem('token')
  }

  getTypeUtilisateur(){
    return GlobalConstants.type_utilisateur();
  }

  getUser(){
    return GlobalConstants.current_user()
  }

  postIsSendOffre(user_id,data){
    return this.http.post(`${GlobalConstants.IS_SEND_OFFRE}${user_id}`,data,{headers:this.getHeader()})
  }

  getServices():Observable<any>{
    return this.http.get(GlobalConstants.GET_SERVICES,{headers:this.getHeader()})
  }

  addService(data){
    let httpOptions = {
      headers: this.getHeader()
    };
    return this.http.post(GlobalConstants.SERVICE_ADD_URL, data,httpOptions)
  }
  
  getServiceById(id):Observable<any>{
    return this.http.get(`${GlobalConstants.GET_SERVICE_BY_ID}${id}`,{headers:this.getHeader()})
  }

  updateService(id, data){
    let httpOptions = {
      headers: this.getHeader()
    };
    return this.http.post(`${GlobalConstants.SERVICE_EDIT}${id}`, data,httpOptions)
  }

  deleteService(id):Observable<any>{
    return this.http.get(`${GlobalConstants.DEL_SERVICE_BY_ID}${id}`,{headers:this.getHeader()})
  }
  
  
}

