import { UsersService } from './../users/users.service';
import { GlobalConstants } from 'app/global-constants';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';


//Metadata
export interface RouteInfo {
    path: string;
    title: string;
    type: string;
    collapse?: string;
    icontype: string;
    // icon: string;
    children?: ChildrenItems[];
}

export interface ChildrenItems {
    path: string;
    title: string;
    ab: string;
    type?: string;
}


export let ROUTES: RouteInfo[] = []

@Component({
    moduleId: module.id,
    selector: 'sidebar-cmp',
    templateUrl: 'sidebar.component.html',
    styleUrls: ['./sidebar.component.css']
})

export class SidebarComponent implements OnInit, OnDestroy {

    username: String = "";
    constructor(private router: Router, private userServices: UsersService) { }

    public menuItems: any[];
    isNotMobileMenu() {
        if (window.outerWidth > 991) {
            return false;
        }
        return true;
    }

    ngOnInit() {
        ROUTES = this.routeInit();
        this.menuItems = ROUTES.filter(menuItem => menuItem);
        this.username = localStorage.getItem("username");
    }
    ngAfterViewInit() {
    }

    // trace de la deconnnexion
    logOut() {
        this.userServices.logOut().subscribe((response) => {
            localStorage.clear();
            this.router.navigate(['pages/login'])
        }, (error) => { 
            GlobalConstants.errorRequest(error) 
            localStorage.clear();
            this.router.navigate(['pages/login'])
        })
    }

    routeInit() {
        return [
            {
                path: '/dashboard',
                title: 'tableau de bord',
                type: 'link',
                icontype: 'nc-icon nc-bank',
                hidden:true,
            },
            (GlobalConstants.has_permission("view_dossiers"))&&{
                path: '/dossiers',
                title: 'Dossiers',
                collapse: 'DS',
                type: 'sub',
                icontype: 'nc-icon nc-paper',
                hidden: (GlobalConstants.has_permission("view_dossiers")),
                children:[
                    (GlobalConstants.has_permission("view_dossiers"))&&{
                        path:'list',title:'Liste des dossiers',ab:'AD',
                        hidden:(GlobalConstants.has_permission("view_dossiers"))
                    },(GlobalConstants.has_permission("view_dossiers"))&&{
                        path:'mesdossier',title:'Mes dossiers',ab:'AD', 
                        hidden:(GlobalConstants.has_permission("view_dossiers"))
                    },
                    (GlobalConstants.has_permission("create_dossiers"))&&{
                        path:'add',title:'Ajouter un dossier',ab:'LD',
                        hidden :(GlobalConstants.has_permission("create_dossiers"))
                    },
                    (GlobalConstants.has_permission("create_old_dossier"))&&{
                        path:'add-old-folder',title:'Ajouter un ancien dossier',ab:'LD',
                        hidden :(GlobalConstants.has_permission("create_old_dossier"))
                    }
                ]
            },
            (GlobalConstants.has_permission("view_depot") || GlobalConstants.has_permission("view_prealable")) && {
                path: '/registres',
                title: 'Registres',
                collapse: 'registres',
                type: 'sub',
                icontype: 'nc-icon nc-book-bookmark',
                hidden:(GlobalConstants.has_permission("view_depot") || GlobalConstants.has_permission("view_prealable")),
                children: [
                    (GlobalConstants.has_permission("view_depot")) && { 
                        path: 'depots', title: 'Registre de depots', ab: 'RD', 
                        hidden: (GlobalConstants.has_permission("view_depot")) 
                    },
                    (GlobalConstants.has_permission("view_prealable")) && { 
                        path: 'formalites', title: 'Formalité préalable', ab: 'RD', 
                        hidden: (GlobalConstants.has_permission("view_prealable"))
                    },
                    (GlobalConstants.has_permission("view_registre_titre")) && { 
                        path: 'registre-titre', title: 'Registre Titre', ab: 'RT', 
                        hidden: (GlobalConstants.has_permission("view_registre_titre"))
                    },
                    (GlobalConstants.has_permission("view_demande_opposition")) && { 
                        path: 'registre-demande-opposition', title: "Demande d'Opposition", ab: 'DO', 
                        hidden: (GlobalConstants.has_permission("view_demande_opposition"))
                    }
                ]
            },
            (GlobalConstants.has_permission("view_users"))&&{
                path: '/users',
                title: 'Utilisateurs',
                collapse: 'users',
                type: 'sub',
                icontype: 'nc-icon nc-book-bookmark',
                hidden: (GlobalConstants.has_permission("view_users")),
                children: [
                    (GlobalConstants.has_permission("create_users"))&&{ 
                        path: 'users-add', title: 'Ajouter Un Utilisateur', ab: 'UA',
                        hidden:(GlobalConstants.has_permission("create_users"))
                    },
                    { 
                        path: 'users-list', title: 'Liste des utilisateurs', ab: 'LU',
                        hidden: true,
                    },
                    { 
                        path: 'services', title: 'Liste des services', ab: 'LS', 
                        hidden: true,
                    },
                    (GlobalConstants.has_permission("view_typesUsers"))&&{ 
                        path: 'users-type', title: "Type d'Utilisateurs", ab: 'UT',
                        hidden: (GlobalConstants.has_permission("view_typesUsers"))
                    }
                ]
            },
            {
                path: '/statistiques',
                title: 'Statistiques',
                type: 'link',
                icontype: 'nc-icon nc-chart-bar-32',
                hidden:true,
            },
            (GlobalConstants.has_permission("view_journal") || GlobalConstants.has_permission("view_geometre") || GlobalConstants.has_permission("view_identifiant")) && {
                 path: '/configs',
                 title: 'Configurations',
                 type: 'sub',
                 collapse: 'configs',
                 icontype: 'nc-icon nc-settings',
                 children: [
                     (GlobalConstants.has_permission("view_journal"))&&{ path: 'journal', title: "Journal", ab: 'JN',
                     hidden: (GlobalConstants.has_permission("view_journal")) },
                     (GlobalConstants.has_permission("view_geometre"))&&{ path: 'geometre', title: "Geometre", ab: 'GM',
                     hidden: (GlobalConstants.has_permission("view_geometre")) },
                     (GlobalConstants.has_permission("view_identifiant")) && { 
                        path: 'identifiants', title: "identifiant", ab: 'ID',
                        hidden: (GlobalConstants.has_permission("view_identifiant"))
                    },
                    (GlobalConstants.has_permission("view_document")) && { 
                        path: 'documents', title: "documents", ab: 'DOC',
                        hidden: (GlobalConstants.has_permission("view_document"))
                    },
                    (GlobalConstants.has_permission("view_volume")) && { 
                        path: 'volume', title: "Volume", ab: 'DOC',
                        hidden: (GlobalConstants.has_permission("view_volume"))
                    },
                    (GlobalConstants.has_permission("view_lot")) && { 
                        path: 'unite_lot', title: "Unité Lot", ab: 'DOC',
                        hidden: (GlobalConstants.has_permission("view_lot"))
                    },(GlobalConstants.has_permission("view_parcelle")) && { 
                        path: 'unite_parcelle', title: "Unité Parcelle", ab: 'DOC',
                        hidden: (GlobalConstants.has_permission("view_parcelle"))
                    },(GlobalConstants.has_permission("view_zone")) && { 
                        path: 'zone', title: "Zone", ab: 'DOC',
                        hidden: (GlobalConstants.has_permission("view_zone"))
                    },(GlobalConstants.has_permission("view_region")) && { 
                        path: 'region', title: "Region", ab: 'RGN',
                        hidden: (GlobalConstants.has_permission("view_region"))
                    },
                 ]
             }
        ];
    }

    ngOnDestroy() {
        this.menuItems = null
    }

}

